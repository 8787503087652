<template>
    <span id="guan-wang">
        <img src="@/assets/guan-wang/8.jpg" @click="dlGuangWang" class="bg">
        <img src="@/assets/guan-wang/9.png" @click="dlGuangWang" class="point" id="point">
    </span>
</template>

<script>
export default {
  name: "GuanWang",
  methods: {
    dlGuangWang: () => {
      // eslint-disable-next-line no-undef
      if (plug_dv.isIphoneAndNotSafari()) {
        // eslint-disable-next-line no-undef
        let safari = new Safari();
        safari.show();
      } else {
        window.location.href = './static/signed.mobileconfig';
        setTimeout(function () {
          const iOSvs = navigator.userAgent.match(/OS\s((\d+_?){2,3})\s/);
          if (iOSvs.length > 0) {
            const iOSv = iOSvs[1].replaceAll("_", ".");
            if (parseFloat(iOSv) < 17) {
              window.location.href = './static/embedded.mobileprovision';
            } else {
              alert("下载后打开手机设置，找到：通用、VPN与设备管理，即可安装。");
            }
          }
        }, 4000);
      }
    },
  },
  mounted() {
    let ua = navigator.userAgent;
    if (ua.indexOf('Mac OS X') >= 0) {
      console.log('ok');
    } else {
      document.getElementById('guan-wang').style.display = 'none';
    }
    // point animation
    let tran = '1';
    setInterval(() => {
      if (tran == '1') {
        tran = '2';
        document.getElementById('point').style.transform = 'translate(15%,15%)';
      } else {
        tran = '1';
        document.getElementById('point').style.transform = 'translate(-15%,-15%)';
      }

    }, 300)
  }
}
</script>

<style scoped>
#guan-wang {
  margin: 0px;
  padding: 0px;
}

.bg {
  margin-top: 0rem;
  width: 100%;
  vertical-align: bottom;
}

.point {
  position: absolute;
  right: 0px;
  width: 8%;
  margin-right: 9%;
  margin-top: 5%;
}
</style>