<template>
    <div id="game">
        <FloatAgent></FloatAgent>
        <img class="img1" src="@/assets/game/2299bg1.jpg" @click="showAgentTest()">
        <div id="bt1">
            <div id="dl1" @click="downloadApp"></div>
            <div id="doc1">
                <img id="doc1-img" :src="doc_button_img" v-on:click="showDoc()">
            </div>
            <div id="agent1">
                <img src="@/assets/game/12.png" v-on:click="showAgent()">
            </div>
            <div id="xlh1">
                <img src="@/assets/game/lxbg1.png">
            </div>
            <div id="xl1">
                <img src="@/assets/game/lux1.png" v-on:click="fXianLu1(1)">
                <img src="@/assets/game/lux2.png" v-on:click="fXianLu1(2)">
                <img src="@/assets/game/lux3.png" v-on:click="fXianLu1(3)">
            </div>
            <div style="height: 5rem;"></div>
            <div class="foot">
                <div class="img2" @click="downloadApp" style="position: relative;">
                    <img src="@/assets/game/7.png" style="display: block;">
                    <img src="@/assets/game/download.png" style="position: absolute; right: 3%; top: 30%; width: 25%;">
                </div>
                <GuanWang></GuanWang>
            </div>
        </div>
    </div>
</template>

<script>
    import GuanWang from "./GuanWang";
    import emitter from "./UniqEvent";
    import FloatAgent from "./FloatAgent";
    import doc_button_img_ios from '../assets/game/11.png';
    import doc_button_img_adr from '../assets/game/11b.png';

    let lastDownloadTime = null;
    export default {
        name: "GameComp",
        components: {FloatAgent, GuanWang},
        created() {
            // eslint-disable-next-line no-undef

        },
        data: function () {
            return {
                xianLu1: '#',
                xianLu2: '#',
                xianLu3: '#',
                z_domain: '',
                doc_button_img: doc_button_img_ios,
                conf_adr_sign: '',
                conf_ios_sign: '',
            };
        },
        methods: {
            showAgentTest: function(){
                let ua = navigator.userAgent;
                console.log(ua);
            },
            downloadAppTrigger: function () {
                let trigger = true;
                if( trigger ){
                  // check if is dl: proxyAccount url param is exists
                  const v_pa = new URLSearchParams(document.location.search).get("proxyAccount");
                  if(!v_pa){
                    trigger = false;
                  }
                }
                if (trigger) {
                    // eslint-disable-next-line no-undef
                    let m = new OpenShare({appKey: "kUdJBiv1yEW1QO2"}, false);
                    m.wakeupOrInstall();
                } else {
                    let u = navigator.userAgent;
                    if (u.indexOf('Macintosh') > -1 || u.indexOf('Mac OS X') > -1) {
                        if(this.conf_ios_sign.indexOf('?') > 0){
                          window.location.href = this.conf_ios_sign;
                        }else{
                          window.location.href = this.conf_ios_sign;
                        }
                    }
                    if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
                        if(this.conf_adr_sign.indexOf('?') > 0){
                          window.location.href = this.conf_adr_sign;
                        }else{
                          window.location.href = this.conf_adr_sign;
                        }
                    }
                }
            },
            downloadApp: function () {
                if (null == lastDownloadTime) {
                    lastDownloadTime = new Date();
                    this.downloadAppTrigger();
                } else {
                    let lastTime = lastDownloadTime.getTime();
                    let thisTime = new Date().getTime();
                    let subTime = thisTime - lastTime;
                    if (subTime >= 1000) {
                        console.log(lastTime, thisTime, (thisTime - lastTime));
                        lastDownloadTime = new Date();
                        this.downloadAppTrigger();
                    }
                }
            },
            fXianLu1: function (key) {
                if (key == 1) {
                    window.open(this.xianLu1);
                } else if (key == 2) {
                    window.open(this.xianLu2);
                } else if (key == 3) {
                    window.open(this.xianLu3);
                }
            },
            showDoc: function () {
                emitter.emit('close-game');
                emitter.emit('show-doc');
            },
            getAgentType: function () {
                let u = navigator.userAgent;
                let isIOS = u.indexOf('Macintosh') > -1 || u.indexOf('Mac OS X') > -1
                let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
                if (isIOS) {
                    return 'ios';
                } else if (isAndroid) {
                    return 'android';
                } else {
                    return 'pc';
                }
            },
            guangwang: function () {
                let u = this.getAgentType();
                if (u == 'ios') {
                    window.location.href = './signed.mobileconfig';
                    setTimeout(() => {
                        window.location.href = './embedded.mobileprovision';
                    }, 4000);
                }
            },
            showAgent: function () {
                emitter.emit('show-agent');
            }
        },
        beforeCreate() {
            document.title = 'APP下载';
            emitter.on('download-app', () => {
                this.downloadApp();
            });
            emitter.on('close-game', () => {
                document.getElementById('game').style.display = 'none';
            });
            emitter.on('show-game', () => {
                document.getElementById('game').style.display = 'block';
            });
        },
        mounted() {
            let f1 = () => {
                let w1 = '70%';
                setInterval(() => {
                    document.getElementById('dl1').style.backgroundSize = w1;
                    if ('70%' == w1) {
                        w1 = '60%';
                    } else {
                        w1 = '70%';
                    }
                }, 300);
            };
            f1();
            // if is ios ? show doc button : hide doc button
            if (navigator.userAgent.indexOf('Mac OS X') >= 0) {
                document.getElementById('doc1-img').setAttribute("src", doc_button_img_ios);
            } else {
                document.getElementById('doc1-img').setAttribute("src", doc_button_img_adr);
            }
            // get xl
            let f2 = ()=>{
                let req = new Request(`/static/xianlu.js?v=${Date.now()}`);
                fetch(req).then(rs=>rs.json()).then(rs=>{
                  this.xianLu1 = rs.data.xl1;
                  this.xianLu2 = rs.data.xl2;
                  this.xianLu3 = rs.data.xl3;
                  this.z_domain = rs.data.zdomain;
                  this.conf_adr_sign = rs.data.adr_sign;
                  this.conf_ios_sign = rs.data.ios_sign;
                });
            };
            f2();
        }
    }
</script>

<style>

    #doc2 div {
        margin: .5rem .5rem;
    }

    .img1 {
        width: 100%;
        margin: 0px;
      vertical-align: middle;
    }

    #bt1 {
        background-image: url("~@/assets/game/2299bg2.jpg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: top 0rem;
    }

    #dl1 {
        position: relative;
        background-image: url("~@/assets/game/2.png");
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 10% 0px;
    }

    #doc1 {
        text-align: center;
        margin: 10px;
        min-height: .5rem;
    }

    #doc1 img {
        width: 50%;
    }

    #agent1 {
        text-align: center;
        margin-bottom: .5rem;
    }

    #agent1 img {
        width: 50%;
    }

    #xlh1 img {
        width: 100%;
        margin-top: 1rem;
    }

    #xl1 {
        background-image: url("~@/assets/game/lxbg2.png");
        background-size: 100%;
        background-position: center;
        background-position-y: 0rem;
        background-repeat: no-repeat;
        margin-bottom: 1rem;
        margin-top: -5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 3% 7%;
    }

    #xl1 img {
        width: 30%;
        height: 30%;
    }

    .img2 {
        width: 100%;
    }

    .img2 img {
        width: 100%;
    }

    .foot {
        position: fixed;
        position: -webkit-sticky;
        bottom: 0px;
        width: 100%;
        max-width: var(--max-width);
    }
</style>