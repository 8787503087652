<template>
    <div id="float-agent">
        <img src="@/assets/float-agent/13.png" v-on:click="showAgent()" style="z-index: 900;">
    </div>
</template>

<script setup>
import emitter from "./UniqEvent";
import {onMounted} from "vue";

function showAgent() {
    emitter.emit('show-agent');
}

onMounted(() => {
    let ua = navigator.userAgent;
    if (!ua.includes('iPhone') && !ua.includes('Android')) {
        let agent = document.querySelector('#float-agent');
        agent.style.setProperty('--float-right', 'calc(50vw - 17.5rem)');
        agent.style.setProperty('--kf-width', '5rem');
    }
});
</script>

<style scoped>
#float-agent {
    --float-right: 0.5rem;
    --kf-width: 4rem;
    position: fixed;
    right: var(--float-right);
    top: calc(50% + 2rem);
    z-index: 5000;
}

#float-agent img {
    width: 20vw;
    max-width: var(--kf-width);
}
</style>