<template>
    <div id="doc">
        <div id="doc-ios">
          <img class="doc-close" src="@/assets/game/5.png" v-on:click="closeDoc()">
          <div v-on:click="showAgent()">看不懂？联系客服，1对1指导安装</div>
          <img class="doc-img" src="@/assets/game/wnsr.png">
          <img class="doc-close" src="@/assets/game/5.png" v-on:click="closeDoc()">
          <div v-on:click="showAgent()">看不懂？联系客服，1对1指导安装</div>
        </div>
        <div id="doc-adr">
          <div id="a1">
            <img :src="img_doc_close" v-on:click="closeDoc">
            <img :src="img_doc_kefu" v-on:click="showAgent()">
          </div>
          <div id="a2">
            <img :src="img_doc_info">
          </div>
          <div id="a3">
            <img :src="img_doc_close" v-on:click="closeDoc">
            <img :src="img_doc_kefu" v-on:click="showAgent()">
          </div>
        </div>
    </div>
</template>

<script>
    import emitter from "./UniqEvent";
    import img_doc_close from '../assets/doc/doc_close.png';
    import img_doc_info from '../assets/doc/doc_info.jpg';
    import img_doc_kefu from '../assets/doc/doc_kefu.png';

    export default {
        name: "DocComp",
        data:function (){
          return {
            img_doc_close: img_doc_close,
            img_doc_info: img_doc_info,
            img_doc_kefu: img_doc_kefu,
          };
        },
        beforeCreate() {

            emitter.on('hide-doc', () => {
                document.getElementById('doc').style.display = 'none';
            });
            emitter.on('show-doc', () => {
              let u = navigator.userAgent;
              let isIOS = u.indexOf('Macintosh') > -1 || u.indexOf('Mac OS X') > -1;
              if(isIOS){
                document.getElementById('doc-adr').style.display = 'none';
              }else{
                document.getElementById('doc-ios').style.display = 'none';
              }
                document.getElementById('doc').style.display = 'block';
            });
        },
        methods: {
            closeDoc: () => {
                emitter.emit('hide-doc');
                emitter.emit('show-game');
            },
            showAgent: () => {
                emitter.emit('show-agent');
            },
        }
    }
</script>

<style scoped>
    #doc {
        color: red;
        display: none;
    }

    .doc-img {
        width: 90vw;
    }

    .doc-close {
        width: 50vw;
        margin-top: 1rem;
    }

    div {
        margin-bottom: 1rem;
    }

    #a1{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    #a1 img{
        display: inline-block;
        height: 2rem;
        margin: 1rem;
    }
    #a2 img{
        width: 100%;
    }

    #a3 img{
        display: block;
        height: 2rem;
        margin: 0 auto;
        margin-bottom: 1rem;
    }
</style>